@import '~ng-zorro-antd/ng-zorro-antd.min.css';

/* << styles    */
.text-xl {
  width: 325px;
  display: inline-block;
}
.text-lg {
  width: 130px;
  display: inline-block;
}
.text-md {
  width: 90px;
  display: inline-block;
}
.text-sm {
  width: 54px;
  display: inline-block;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-tooltip {
  max-width: 400px !important;
}

.ant-list-lg .ant-list-item {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background-color: lightgray !important;
}

.ant-modal {
  width: 840px !important;
}

nz-upload {
  width: 100% !important;
}

a {
  line-height: 32px;
}

.ant-checkbox-wrapper {
  line-height: 32px !important;
}

::ng-deep .ngx-charts .bar {
  stroke: yellowgreen;
}

.save-button {
  margin: 0;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  margin-left: 50%;
  transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.ce-inline-tool--active {
  fill: #388ae5;
}

.tagify--select {
  margin-top: 16px;
}

tags.tagify.cdx-input {
  margin-top: 16px;
}

.tagify__tag__removeBtn {
  display: none;
}
